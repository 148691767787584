import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTopRoute from "./ScrollToTopRoute";
import HomeHosting from "./Pages/HomeHosting";
import Contact from "./Pages/Contact";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={HomeHosting} />
          <ScrollToTopRoute path="/Contact" component={Contact} />
        </Switch>
      </Router>
    );
  }
}

export default App;
