import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class HostingActionTwo extends Component {
    render(){
        return(
            <section className="h_action_promo_area">
                <div className="overlay_bg"></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className="h_promo_content">
                                <h2>Start growing with us today!</h2>
                            </div>
                        </div>
                        <div className="col-md-4 text-right">
                            <Link className="hosting_btn btn_hover" to="/Contact">Get a free Estimate</Link>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default HostingActionTwo;
