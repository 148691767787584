import React from 'react';
import Sectitle from '../Title/Sectitle';
import HRServiceItems from './HostingServiceitem';

const HostingService =()=> {
    return(
        <section className="hosting_service_area sec_pad">
            <div className="container">
                <Sectitle sClass="hosting_title text-center" Title="Need a Website" TitleP="Get a fully custom website to support your small business."/>
                <div className="row">
                    <HRServiceItems hostingicon="icon1.png" hostingserviceTitle="Web Hosting" hostingservicedescription="We'll take care of everything so you can focus on what matters in your business"/>
                    <HRServiceItems hostingicon="icon2.png" hostingserviceTitle="Professional Services" hostingservicedescription="Our team will take you through the whole process, step by step"/>
                    <HRServiceItems hostingicon="icon3.png" hostingserviceTitle="Data Storage" hostingservicedescription="Have large data sets you need to manage, no sweat"/>
                    <HRServiceItems hostingicon="icon4.png" hostingserviceTitle="Securely and Safely" hostingservicedescription="Rest assured that your custom solution will provide a save and secure system for you"/>
                    <HRServiceItems hostingicon="icon5.png" hostingserviceTitle="Move faster" hostingservicedescription="Empower your business with a speed to market that is unmatched for small businesses"/>
                    <HRServiceItems hostingicon="icon6.png" hostingserviceTitle="Easily integrate" hostingservicedescription="Get a site a fully integrated site that does what you need when you need it"/>
                </div>
            </div>
        </section>
    )
}
export default HostingService;