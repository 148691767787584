import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import HostingBanner from '../components/Banner/HostingBanner';
import HostingService from '../components/Service/HostingService';
import HostingAction from '../components/HostingAction';
import HostingActionTwo from '../components/HostingActionTwo';
import FooterErp from '../components/Footer/FooterErp';
import FooterData from '../components/Footer/FooterData';

const HomeHosting =()=> {
    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo"/>
            <HostingBanner/>
            <HostingService/>
            <HostingAction aClass="h_action_area" aimage="business2.png" colClass="col-lg-6" colClassTwo="col-lg-6" Atitle="Managed Wordpress" Atext="Want to manage your own content? Let us create a custom managed wordpress site to match your needs."/>
            <HostingActionTwo/>
            <FooterErp FooterData={FooterData}/>
        </div>
    )
}
export default HomeHosting;