import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const HostingBanner =() =>{
    return(
        <section className="hosting_banner_area">
            <Fade bottom cascade>
                <ul className="list-unstyled b_line">
                    <li className="wow fadeInUp" data-wow-delay="0.4s"><img src={require("../../img/hosting/line_01.png")} alt=""/></li>
                    <li className="wow fadeInUp" data-wow-delay="0.7s"><img src={require("../../img/hosting/line_02.png")} alt=""/></li>
                    <li className="wow fadeInUp" data-wow-delay="0.9s"><img src={require("../../img/hosting/line_03.png")} alt=""/></li>
                    <li className="wow fadeInUp" data-wow-delay="1.2s"><img src={require("../../img/hosting/line_04.png")} alt=""/></li>
                    <li className="wow fadeInUp" data-wow-delay="0.4s"><img src={require("../../img/hosting/line_05.png")} alt=""/></li>
                    <li className="wow fadeInUp" data-wow-delay="1s"><img src={require("../../img/hosting/line_06.png")} alt=""/></li>
                    <li className="wow fadeInUp" data-wow-delay="1s"><img src={require("../../img/hosting/line_07.png")}alt=""/></li>
                    <li className="wow fadeInUp" data-wow-delay="1.3s"><img src={require("../../img/hosting/line_08.png")} alt=""/></li>
                </ul>
            </Fade>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-7 d-flex align-items-center">
                        <div className="hosting_content">
                            <h2 className="wow fadeInUp" data-wow-delay="0.3s">Custom Web Development</h2>
                            <p className="wow fadeInUp" data-wow-delay="0.5s">Let us help you with all your web development needs with a free estimate. We make it easy so you can focus on what matters.</p>
                            <Link className="hosting_btn btn_hover wow fadeInUp" to="/Contact" data-wow-delay="0.7s">Get Started</Link>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                        <img className="img-fluid wow fadeInRight" data-wow-delay="0.7s" src={require("../../img/hosting/business1.png")} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HostingBanner;