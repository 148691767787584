import React, {Component} from 'react';

import { Link } from 'react-router-dom';

class HostingAction extends Component {
    render(){
        let {aClass, aimage, colClass, colClassTwo, Atitle, Atext}= this.props;
        return(
            <section className={`${aClass}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className={`${colClass}`}>
                            <div className="h_action_img">
                                <img className="img-fluid" src={require ("../img/hosting/" + aimage)} alt=""/>
                            </div>
                        </div>
                        <div className={`${colClassTwo}`}>
                            <div className="h_action_content">
                                <h2>{Atitle}</h2>
                                <p>{Atext}</p>
                                <Link className="hosting_btn btn_hover wow fadeInUp" to="/Contact" data-wow-delay="0.7s">Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default HostingAction;
