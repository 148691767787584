import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';

class FooterErp extends Component {
    render() {
        let FooterData = this.props.FooterData;
        let { fClass } = this.props;

        return (
            <footer className={`footer_area h_footer_dark ${fClass}`}>
                <div className="container">
                    <div className="row">
                        {
                            FooterData.CompanyWidget.map(widget => {
                                return (
                                    <Reveal effect="fadeInLeft" duration={500} key={1}>
                                        <img className="footer-image" src={require("../../img/grayscale-transparent.png")} alt="" />
                                    </Reveal>
                                )
                            })
                        }
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterErp;