import BaseService from '../utilities/baseservice'

class EmailService {
    PATH = '/email';
    async sendEmail(props, callBack){
        console.log("email service");
        BaseService.post(this.PATH, props, (status, data) => {
            callBack({ status: status, data: data });
        });
    }

}

export default new EmailService;
