import React, { Component } from 'react';
import EmailService from '../services/email';

class Contacts extends Component {
    state = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }

    // handle the value
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    }

    submitForm = (e) => {
        const { name, email, subject, message } = this.state;

        EmailService.sendEmail({
            name: name,
            email: email,
            subject: subject,
            message: message,
        }, (rtnObject) => {
            //TODO: Trigger notification to user
            console.log(rtnObject);
        });

        // Reset the form fields
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
            emailStatus: ''
        });
        e.preventDefault();
    }

    render() {
        const { name, email, subject, message, emailStatus } = this.state;
        return (
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            {/* <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Office Address</h6>
                                <p className="f_400 f_size_15">8180 Hidden Canyon Dr NE Ada, MI 49301</p>
                            </div> */}
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contact Info</h6>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Phone:</span> <a href="tel:3024437488">(734) 474 4707</a></p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Email:</span> <a href="mailto:sales@rosecanyoncompanies.com">sales@rosecanyoncompanies.com</a></p>
                            </div>
                        </div>
                        <div className="contact_form col-lg-8">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Leave a Message</h2>
                            <form onSubmit={this.submitForm} className="contact_form_box" method="post" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" value={name} id="name" name="name" placeholder="Your Name" onChange={this.handleChange('name')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" value={email} name="email" id="email" placeholder="Your Email" onChange={this.handleChange('email')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" value={subject} id="subject" name="subject" placeholder="Subject" onChange={this.handleChange('subject')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea value={message} onChange={this.handleChange('message')} name="message" id="message" cols="30" rows="10" placeholder="Enter Your Message . . ."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn_three">Send Message</button>
                            </form>
                            {emailStatus ? emailStatus : null}
                            <div id="success">Your message succesfully sent!</div>
                            <div id="error">Opps! There is something wrong. Please try again</div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default Contacts;